<template>
  <div>
    <h1 :class="$style.pageTitle">所属ユーザー管理</h1>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div v-if="loading != 2" class="text-center">
              <a-spin tip="Loading..." />
            </div>
            <form-belong-users
              v-if="loading == 2"
              :propAllUsers="allUsers"
              :propActiveUsers="belongUsers"
              :backPath="backPath"
              :putPath="putPath"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Vue from 'vue'
import FormBelongUsers from '../../../components/FormComponents/BelongUsers'

export default {
  components: {
    FormBelongUsers,
  },
  data() {
    return {
      loading: 0,
      allUsers: [],
      belongUsers: [],
      backPath: '/branches/' + this.$route.params.id,
      putPath: 'branches_users/' + this.$route.params.id,
    }
  },
  created() {
    const resultBranchUsers = Vue.prototype.$api.send('get', 'branches/' + this.$route.params.id + '/users')
    resultBranchUsers.then(response => {
      this.loading++
      this.belongUsers = response.map((item) => { return item.id })
    })
      .catch(error => {
        this.$notification['error']({
          message: error.status + ': 所属ユーザーの取得に失敗しました。',
        })
        this.loading++
      })

    const resultAllUsers = Vue.prototype.$api.send('get', 'users')
    resultAllUsers.then(response => {
      this.loading++
      this.allUsers = response.map((item) => { return { label: item.name, value: item.id, checked: true, role: item.role } })
    })
      .catch(error => {
        this.$notification['error']({
          message: error.status + ': ユーザーの取得に失敗しました。',
        })
        this.loading++
      })
  },
}

</script>
<style lang="scss" module>
@import '@/assets/styles/mixins.scss';
</style>
